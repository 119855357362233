.Botanicos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 70vh;

    background-image: url('./imgs/fondo2.jpg');
    background-position: center;

    color: white;
}

.Botanicos h3 {
    font-size: 3rem;
    text-align: center;
}

.Botanicos .descripcion {
    width: 60%;
    padding: 50px;
    padding-top: 0px;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.Botanicos .descripcion p {
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
}

@media (min-width: 700px) {
    .Botanicos {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        height: 70vh;
    }
    
    .Botanicos h3 {
        font-size: 2.5rem;
        text-align: center;
    }
    
    .Botanicos .descripcion {
        width: 60%;
        padding: 50px;
        padding-top: 0px;
    }
    
    .Botanicos .descripcion p {
        font-size: 1.2rem;
    }
}

@media (max-width: 700px) {
    .Botanicos {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        height: 70vh;
    }
    
    .Botanicos h3 {
        font-size: 2rem;
        text-align: center;
    }
    
    .Botanicos .descripcion {
        width: 60%;
        padding: 50px;
        padding-top: 0px;
    }
    
    .Botanicos .descripcion p {
        font-size: 1rem;
    }
}