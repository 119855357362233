* {
    @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
}

html,
body {
    background-color: #FBFEF9;
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    font-family: "Work Sans", serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
}

.equipoh3{
    /* height: 100px; */
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;

    text-align: center;
    font-size: 3rem;
    color: #44164C;
}