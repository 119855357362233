#contacto{
    width: 100%;
    margin-top: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: var(--GrisClaro);
}
#contacto .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#contacto .title img {
    height: 50px;
}

#contacto .title h2 {
    text-align: center;
    font-size: 3rem;
    color: #44164C;
}

#contacto form {
    width: 80%;

    margin-top: 50px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

#contacto form button{
    height: 60px;

    border-radius: 10px;

    background-color: #E9BCB9;
    background-color: #44164C;
    border: none;

    color: #44164C;
    color: white;
    font-size: 2rem;

    cursor: pointer;

    transition: all 0.5s;
}
#contacto form button:hover{
    background-color: #44164C;
    background-color: #E9BCB9;
    color: #44164C;
}


@media (max-width: 500px) {
    #contacto .title {
        margin-left: 0px;
        gap: 25px;
    }

    #contacto .title img {
        height: 30px;
    }

    #contacto .title h2 {
        font-size: 2rem;
    }
}