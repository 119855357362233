.Batchs {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Batchs .izquierda {
    width: 40%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    /* background-color: red; */
}
.Batchs .izquierda .img{
    margin: 0;
    padding: 0;

    width: 80%;
    /* overflow: hidden; */
}
.Batchs .izquierda img{
    margin: 0;
    padding: 0;

    width: 100%;

    filter: drop-shadow(0 50px 50px rgba(0, 0, 0, 0.479));
}
.Batchs .derecha {
    padding: 0;
    padding-left: 50px;
    padding-right: 50px;
    margin: 0;

    width: 50%;

    text-align: center;
    color: #44164C;

    font-weight: 500;
    font-size: 1.5rem;
}

@media (max-width: 700px) {
    .Batchs {
        flex-direction: column-reverse;
    }
    .Batchs .izquierda .img{
        width: auto;
    }
    .Batchs .izquierda img{
        height: 400px;
        width: 400px;
    }
    .Batchs .derecha {
        width: 80%;
        font-size: 1.3rem;
    }
}